import React from 'react';

class PasswordRepeatField extends React.Component {
    render() {
        //debugger;
        return (

            <div className="d-block mb-2 mb-sm-0 d-sm-table-row">
                <label className="d-inline-block d-sm-table-cell no-white-space">
                    {this.props.localizedLabelRepeatPassword}:
                </label>
                <input
                    className={"d-block d-sm-table-cell form-control" + (this.props.passwordValidationMessage === "" ? "" : " is-invalid")}
                    type="password"
                    autoComplete="off"
                    value={this.props.passwordRepeat}
                    onChange={this.props.onChangeTextField}
                    name="passwordRepeat"
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">
                    {this.props.passwordValidationMessage}
                </div>
                <div style={{ 'fontSize': 12, 'color': '#dc3545' }}>
                    {this.props.policyLabelMessage}
                    {/* Passordet må ha en lengde på minst 8 tegn og inneholde følgende som et minimum: én stor bokstav, én liten bokstav og ett nummer. */}
                </div>
            </div>
        );
    }
}
export default PasswordRepeatField;