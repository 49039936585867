export class PasswordRegexPatterns {
    //BACKE
    public static MIN_8_ONE_UPPER_ONE_LOWER_ONE_NUMBER_AND_ALLOWED_SPECIAL_CHARS = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!,*@#$%^&_+.={};'a-zA-Z\d]{8,}$/;

    //BRAV - added <>? to the symbols against the MIN_8_ONE_UPPER_ONE_LOWER_ONE_NUMBER_AND_ALLOWED_SPECIAL_CHARS
    public static MIN_8_ONE_UPPER_ONE_LOWER_ONE_NUMBER_AND_ALLOWED_SPECIAL_CHARS_1 = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!,*@#$%^&_+.={};'<>?a-zA-Z\d]{8,}$/;

    //ARRIBATEC
    public static MIN_8_ONE_UPPER_ONE_LOWER_ONE_NUMBER_AND_ALLOWED_SPECIAL_CHARS_PLUS_SPACE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[!,*@# $%^&_+.={};'a-zA-Z\d]{8,}$/;

    //GE
    //Min 8 chars, at least one upper, one lower, one number
    //public static MIN_8_ONE_UPPER_ONE_LOWER_ONE_NUMBER = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/; //27.06.2022 made obsolete by request form GE
    public static MIN_16_ONE_UPPER_ONE_LOWER_ONE_NUMBER = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{16,}$/;


    //Minimum of ten characters and contain at least one uppercase letter, one lowercase letter and one number, does not allow special chars.
    public static MIN_10_ONE_UPPER_ONE_LOWER_ONE_NUMBER = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/;

    //BOLIGPARTNER
    //ALL KOPI
    //Minimum of ten characters and contain at least one uppercase letter, one lowercase letter and one number, allow special chars.
    public static MIN_10_ONE_UPPER_ONE_LOWER_ONE_NUMBER_SPECIAL_ALLOWED = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/;
    public static CUSTOMER_SPECIFIC_PASSWORD_COMPLEXITY_STRING__MAKE_GRAPHICS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/;

}