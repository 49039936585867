import React from 'react';



class MobilePhoneField extends React.Component {

    render() {
        return (
            // is-invalid
            <div className="d-block mb-2 mb-sm-0 d-sm-table-row">
                <label className="d-inline-block d-sm-table-cell no-white-space">
                    {this.props.localizedLabelName}:
                </label>
                <div className="d-block d-sm-table-cell">
                    <div className="d-flex flex-row">
                        <input
                            className="form-control mr-2"
                            style={{ width: '80px' }}
                            type="text"
                            autoComplete="__disabled"
                            placeholder="+47"
                            value={this.props.countryCode}
                            name="countryCode"
                            onChange={this.props.onChangeTextField}
                            disabled={this.props.disabled} />
                        <input
                            className={"form-control" + (this.props.mobilePhoneValidationMessage === "" ? "" : " is-invalid")}
                            type="text"
                            autoComplete="__disabled"
                            placeholder=""
                            value={this.props.mobilePhone}
                            name="mobilePhone"
                            onChange={this.props.onChangeTextField}
                            disabled={this.props.disabled} />
                    </div>
                    <div className="d-block invalid-feedback">
                        {this.props.mobilePhoneValidationMessage}
                    </div>
                    {/* <div className="d-block invalid-feedback">
                        Please provide phonenumner
                    </div>
                    <div className="d-block invalid-feedback">
                        Please provide country code
                    </div> */}
                </div>
            </div>
        );
    }
}
export default MobilePhoneField;