import React from 'react';

class ResetPasswordCommand extends React.Component {
    render() {
        let button = null;
        if (this.props.operationInProgress) {
            button =
                <button className="btn btn-primary btn-block mb-2 mb-sm-0" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {this.props.localizedStringResetingPassword}
                </button>;
        }
        else {
            button =
                <button className="btn btn-primary btn-block mb-2 mb-sm-0" type="button"
                    onClick={this.props.onClickResetPassword} >
                    {this.props.localizedStringResetPassword}
                </button>
        }
        return (
            <div>
                {button}
            </div>
        );
    }
}
export default ResetPasswordCommand;
