import React from 'react';

class LoginNameField extends React.Component {

    render() {
        return (
            <div className="d-block mb-2 mb-sm-0 d-sm-table-row">
                <label className="d-inline-block d-sm-table-cell no-white-space">
                    {this.props.localizedLabelName}:
                </label>
                <input
                    className={"d-block d-sm-table-cell form-control" + (this.props.loginNameValidationMessage === "" ? "" : " is-invalid")}
                    type="text"
                    autoFocus="autofocus"
                    autoComplete="__disabled"
                    value={this.props.loginName}
                    onChange={this.props.onChangeTextField}
                    name="loginName"
                    disabled={this.props.disabled}
                    placeholder=""
                    required={true}
                />
                <div className="invalid-feedback">
                    {this.props.loginNameValidationMessage}
                </div>
            </div>
        );
    }
}

export default LoginNameField;