import React from 'react';

class PasswordField extends React.Component {
    render() {
        return (
            <div className="d-block mb-2 mb-sm-0 d-sm-table-row">
                <label className="d-inline-block d-sm-table-cell no-white-space">
                    {this.props.localizedLabelNewPassword}:
                </label>
                <input
                    className={"d-block d-sm-table-cell form-control" + (this.props.passwordValidationMessage === "" ? "" : " is-invalid")}
                    type="password"
                    autoComplete="off"
                    value={this.props.password}
                    onChange={this.props.onChangeTextField}
                    name="password"
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">
                    {this.props.passwordValidationMessage}
                </div>
            </div>






        );
    }
}
export default PasswordField;

