import React from 'react';
import LoginNameField from './fields/LoginNameField';
import MobilePhoneField from './fields/MobilePhoneField';
import RequestVerificationCodeCommand from './commands/RequestVerificationCodeCommand';
import VerificationCodeField from './fields/VerificationCodeField';
import ConfirmVerificationCodeCommand from './commands/ConfirmVerificationCodeCommand';
import PasswordField from './fields/PasswordField';
import PasswordRepeatField from './fields/PasswordRepeatField';
import ResetPasswordCommand from './commands/ResetPasswordCommand';
//import { CustomerConfig } from '../customizations/CustomerConfig';
import { PasswordRegexPatterns } from '../customizations/PasswordRegexPatterns';
//import VerificationCodeTimer from './fields/VerificationCodeTimer';
//let Recaptcha = require('react-recaptcha');



class Home extends React.Component {
    state = {
        //endpointRootUrl: "https://iuapwdrsttestportal.azurewebsites.net",
        //endpointRootUrl: "https://ua-sspr-bp-test-func.azurewebsites.net",
        //endpointRootUrl: "https://ua-sspr-bp-prod-func.azurewebsites.net",
        endpointRootUrl: "",
        tenantId: "",

        customerConfig: {},

        //wfStep: "initial",
        wfStep: "",
        //wfStep: "passwordEntry",

        operationInProgress: false,

        //loginName: "ru1@innitusermgmttestcustomer1.onmicrosoft.com",
        loginName: "",
        loginNameDisabled: false,
        loginNameValidationMessage: "",

        //countryCode: "+381",
        countryCode: "+47",
        //mobilePhone: "648962420",
        mobilePhone: "",
        mobilePhoneDisabled: false,
        mobilePhoneValidationMessage: "",

        verificationCode: "",
        verificationCodeDisabled: false,
        verificationCodeValidationMessage: "",
        verificationCodeValidMinutes: 3,//there is also corresponding setting in azure functions

        password: "",
        passwordRepeat: "",
        passwordValidationMessage: "",

        timerMessage: "Verification code is valid for ",
        timerMinutes: "",
        timerSeconds: "",

        timerFunction: null,

        reCaptchaResponse: "",
        reCaptchaValidationMessage: "",

        localizationStrings: {}
    }

    // // create a variable to store the component instance
    // recaptchaInstance = null;

    //callback = () => {
    //    //debugger;
    //}

    //verifyReCaptchaCallback = (response) => {
    //    //debugger;
    //    this.setState({
    //        reCaptchaResponse: response,
    //        reCaptchaValidationMessage: ""
    //    })
    //    console.log(response);
    //}
    // resetRecaptcha = () => {
    //     recaptchaInstance.reset();  
    // };



    async componentWillMount() {
        //debugger;
        const endpointRootUrl = process.env.REACT_APP_ENDPOINT_ROOT_URL;

        //I don't want to take the tenant from env, but from query string
        //const tenantId = process.env.REACT_APP_TENANT_ID;

        //----------------------------
        let tenantId = "";

        if (window.location.search.indexOf("?") != -1) {
            let params = window.location.search.split("?")[1];
            if (params) {
                if (params.indexOf("=") != -1) {
                    let tenantParam = params.split("=")[1];
                    if (tenantParam) {
                        tenantId = tenantParam;
                    }
                }
                else {
                    //the query string is invalid 
                }
            }
        }
        else {
            //there is no query string in the url, show some error
        }
        //----------------------------
        //let configFileResponse = await fetch('app-config.json');
        //console.log(configFileResponse);
        //debugger;
        try {
            if (endpointRootUrl && endpointRootUrl != "" &&
                tenantId && tenantId != "") {

                //get the config for current tenant
                let customerConfig = await this.getCustomerConfig(tenantId);

                if (customerConfig != null) {
                    console.log("GET CUSTOMER CONFIG SUCESS !!!");
                    console.log(customerConfig);
                    //debugger;
                    //resolve the localization strings
                    const localizationStrings = await this.getLocalizationStrings(customerConfig.uiLanguage);
                    //debugger;
                    //the customer config has the password policy message not localized/resolved
                    customerConfig.passwordPolicy.policyLabelMessage = this.resolveLocalizedPolicyLabel(customerConfig.passwordPolicy.regexPatternKey, localizationStrings);
                    //debugger;

                    this.setState({
                        wfStep: "initial",
                        //wfStep: "passwordEntry",
                        endpointRootUrl: endpointRootUrl,
                        tenantId: tenantId,
                        customerConfig: customerConfig,
                        localizationStrings: localizationStrings
                    });
                    //debugger;
                }
                else {
                    console.log("GET CUSTOMER CONFIG ERROR !!!");
                    this.setState({
                        wfStep: "errorGettingAppConfig",
                    });
                }
            }
            else {
                //probably the tenant id is missing
                if (tenantId == "") {
                    //the tenant id parameter is not supplied
                    this.setState({
                        wfStep: "errorTenantIdQueryParameterIsNotSupplied",
                    });
                }
            }
        }
        catch (ex) {
            debugger;
            console.log(ex);
            this.setState({
                wfStep: "errorGettingAppConfig",
            });
        }
    }

    async getLocalizationStrings(uiLanguage) {
        //debugger;
        const localizationResourcesFile = await fetch(`localization/${uiLanguage}.json`);
        //debugger;
        const localizationResources = await localizationResourcesFile.json();
        //debugger;
        return localizationResources;
    }
    resolveLocalizedPolicyLabel(regexPatternKey, localizationStrings) {
        debugger;
        let resolvedPolicyLabel = localizationStrings[regexPatternKey];
        resolvedPolicyLabel = this.getMultilineMessageWithNewLines(resolvedPolicyLabel);
        return resolvedPolicyLabel;

    }


    getMultilineMessageWithNewLines(message) {
        let result = message;

        if (message.indexOf("###NEW_LINE###") != -1) {
            //contains new line constants
            let rows = message.split("###NEW_LINE###");

            var rowLis = [];

            for (let i = 0; i < rows.length; i++) {
                const row = rows[i];
                rowLis.push(<li>{row}</li>);
            }
            result = <ul style={{ marginLeft: "-20px" }}>{rowLis}</ul>;
        }
        return result;
    }

    render() {
        //console.log("entered render")
        let form = null;

        // let tempPassMessage = this.state.localizationStrings.CUSTOMER_SPECIFIC_PASSWORD_COMPLEXITY_STRING__MAKE_GRAPHICS

        // if (tempPassMessage != null) {


        //     tempPassMessage = this.getMultilineMessageWithNewLines(tempPassMessage);
        // }


        switch (this.state.wfStep) {
            // case "loading":
            //     form = <div>
            //         <label className="d-block">
            //             <h3>Loading ...</h3>
            //         </label>
            //     </div>
            //     break;
            case "initial":
                form = <>
                    <form className="d-flex flex-column d-sm-table">
                        <LoginNameField
                            loginName={this.state.loginName}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.loginNameDisabled}
                            loginNameValidationMessage={this.state.loginNameValidationMessage}
                            localizedLabelName={this.state.localizationStrings.EMAIL_OR_LOGIN_NAME} />
                        <MobilePhoneField
                            mobilePhone={this.state.mobilePhone}
                            countryCode={this.state.countryCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.mobilePhoneDisabled}
                            mobilePhoneValidationMessage={this.state.mobilePhoneValidationMessage}
                            localizedLabelName={this.state.localizationStrings.MOBILE_PHONE} />
                    </form>
                    <RequestVerificationCodeCommand
                        onClickRequestVerificationCode={this.onClickRequestVerificationCode}
                        operationInProgress={this.state.operationInProgress}
                        sendVerificationCodeLocalizedString={this.state.localizationStrings.SEND_VERIFICATION_CODE}
                        sendingVerificationCodeLocalizedString={this.state.localizationStrings.SENDING_VERIFICATION_CODE} />
                </>;
                break;
            case "checkVerificationCode":
                form = <>
                    <form className="d-flex flex-column d-sm-table">
                        <LoginNameField
                            loginName={this.state.loginName}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.loginNameDisabled}
                            loginNameValidationMessage={this.state.loginNameValidationMessage}
                            localizedLabelName={this.state.localizationStrings.EMAIL_OR_LOGIN_NAME} />
                        <MobilePhoneField
                            mobilePhone={this.state.mobilePhone}
                            countryCode={this.state.countryCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.mobilePhoneDisabled}
                            mobilePhoneValidationMessage={this.state.mobilePhoneValidationMessage}
                            localizedLabelName={this.state.localizationStrings.MOBILE_PHONE} />
                        <VerificationCodeField
                            verificationCode={this.state.verificationCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.verificationCodeDisabled}
                            verificationCodeValidationMessage={this.state.verificationCodeValidationMessage}
                            localizedLabelName={this.state.localizationStrings.VERIFICATION_CODE} />
                    </form>
                    <ConfirmVerificationCodeCommand
                        onClickConfirmVerificationCode={this.onClickConfirmVerificationCode}
                        operationInProgress={this.state.operationInProgress}
                        confirmingVerificationCodeLocalizedString={this.state.localizationStrings.CONFIRMING_VERIFICATION_CODE}
                        confirmVerificationCodeLocalizedString={this.state.localizationStrings.CONFIRM_VERIFICATION_CODE} />
                </>;
                break;
            case "requestVerificationCodeError":
                //the entered login name and mobile phone is not a match
                form = <>
                    <form className="d-flex flex-column d-sm-table">
                        <LoginNameField
                            loginName={this.state.loginName}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.loginNameDisabled}
                            loginNameValidationMessage={this.state.loginNameValidationMessage}
                            localizedLabelName={this.state.localizationStrings.EMAIL_OR_LOGIN_NAME} />
                        <MobilePhoneField
                            mobilePhone={this.state.mobilePhone}
                            countryCode={this.state.countryCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.mobilePhoneDisabled}
                            mobilePhoneValidationMessage={this.state.mobilePhoneValidationMessage}
                            localizedLabelName={this.state.localizationStrings.MOBILE_PHONE} />
                    </form>
                    <label className="d-block">
                        <div className="alert alert-danger" role="alert">
                            {this.state.localizationStrings.INCORRECT_USER_NAME_OR_PHONE_NUMBER_TRY_AGAIN}
                        </div>
                    </label>
                    <RequestVerificationCodeCommand
                        onClickRequestVerificationCode={this.onClickRequestVerificationCode}
                        operationInProgress={this.state.operationInProgress}
                        sendVerificationCodeLocalizedString={this.state.localizationStrings.SEND_VERIFICATION_CODE}
                        sendingVerificationCodeLocalizedString={this.state.localizationStrings.SENDING_VERIFICATION_CODE} />
                </>;
                break;
            case "checkVerificationCodeError":
                //the entered verification code was not correct
                form = <>
                    <form className="d-flex flex-column d-sm-table">
                        <LoginNameField
                            loginName={this.state.loginName}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.loginNameDisabled}
                            loginNameValidationMessage={this.state.loginNameValidationMessage}
                            localizedLabelName={this.state.localizationStrings.EMAIL_OR_LOGIN_NAME} />
                        <MobilePhoneField
                            mobilePhone={this.state.mobilePhone}
                            countryCode={this.state.countryCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.mobilePhoneDisabled}
                            mobilePhoneValidationMessage={this.state.mobilePhoneValidationMessage}
                            localizedLabelName={this.state.localizationStrings.MOBILE_PHONE} />
                        <VerificationCodeField
                            verificationCode={this.state.verificationCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.verificationCodeDisabled}
                            localizedLabelName={this.state.localizationStrings.VERIFICATION_CODE} />
                    </form>
                    <label className="d-block">
                        <div className="alert alert-danger" role="alert">
                            {this.state.localizationStrings.THE_VERIFICATION_CODE_IS_NOT_VALID}
                        </div>
                    </label>
                    <label className="d-block">
                        {this.state.timerMessage} {this.state.timerMinutes + "m " + this.state.timerSeconds + "s "}
                    </label>
                    <ConfirmVerificationCodeCommand
                        onClickConfirmVerificationCode={this.onClickConfirmVerificationCode}
                        operationInProgress={this.state.operationInProgress}
                        confirmingVerificationCodeLocalizedString={this.state.localizationStrings.CONFIRMING_VERIFICATION_CODE}
                        confirmVerificationCodeLocalizedString={this.state.localizationStrings.CONFIRM_VERIFICATION_CODE} />
                </>;
                break;
            case "passwordEntry":
                //debugger;
                //the verification code is correct, show the password entry controls
                form = <>
                    <form className="d-flex flex-column d-sm-table">
                        <LoginNameField
                            loginName={this.state.loginName}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.loginNameDisabled}
                            loginNameValidationMessage={this.state.loginNameValidationMessage}
                            localizedLabelName={this.state.localizationStrings.EMAIL_OR_LOGIN_NAME} />
                        <MobilePhoneField
                            mobilePhone={this.state.mobilePhone}
                            countryCode={this.state.countryCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.mobilePhoneDisabled}
                            mobilePhoneValidationMessage={this.state.mobilePhoneValidationMessage}
                            localizedLabelName={this.state.localizationStrings.MOBILE_PHONE} />
                        <VerificationCodeField
                            verificationCode={this.state.verificationCode}
                            onChangeTextField={this.onChangeTextField}
                            disabled={this.state.verificationCodeDisabled}
                            localizedLabelName={this.state.localizationStrings.VERIFICATION_CODE} />
                        <PasswordField
                            password={this.state.password}
                            onChangeTextField={this.onChangeTextField}
                            passwordValidationMessage={this.state.passwordValidationMessage}
                            localizedLabelNewPassword={this.state.localizationStrings.NEW_PASSWORD} />
                        <PasswordRepeatField
                            passwordRepeat={this.state.passwordRepeat}
                            onChangeTextField={this.onChangeTextField}
                            passwordValidationMessage={this.state.passwordValidationMessage}
                            policyLabelMessage={this.state.customerConfig.passwordPolicy.policyLabelMessage}
                            localizedLabelRepeatPassword={this.state.localizationStrings.REPEAT_PASSWORD} />
                    </form>
                    <ResetPasswordCommand
                        onClickResetPassword={this.onClickResetPassword}
                        operationInProgress={this.state.operationInProgress}
                        localizedStringResetPassword={this.state.localizationStrings.RESET_PASSWORD}
                        localizedStringResetingPassword={this.state.localizationStrings.RESETING_PASSWORD} />
                </>;

                break;
            case "passwordChangedSuccess":
                //display message that the password has been changed successfuly
                form = <>
                    <label className="d-block">
                        <p>{this.state.localizationStrings.PASSWORD_CHANGE_SUCCESS_MESSAGE}</p>
                    </label>
                </>
                break;
            case "errorGettingAppConfig":
                form = <>
                    <label className="d-block">
                        <h4>{this.state.localizationStrings.THE_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT}</h4>
                    </label>
                </>
                break;
            case "errorTenantIdQueryParameterIsNotSupplied":
                form = <>
                    <label className="d-block">
                        <h4>{this.state.localizationStrings.YOU_CANNOT_RESET_PASSWORD_FROM_THIS_URL_MESSAGE}</h4>
                    </label>
                </>
                break;

            default:
                break;
        }

        // let homePageLogoPictureUrl = "";
        // if (this.state.customerConfig != null) {
        //     homePageLogoPictureUrl = this.state.customerConfig.homePageLogoPictureUrl;
        // }

        if (this.state.customerConfig.tenantId != null) {
            var elem = document.getElementById("logoImageDiv");
            if (elem != null) {
                elem.classList.add(`hk-logo-${this.state.customerConfig.tenantId}`);
            }

            var body = document.getElementsByTagName("body");
            if (body != null) {
                body[0].classList.add(`background-${this.state.customerConfig.tenantId}`);
            }
        }



        return (
            <div id="content" className="container-fluid pad-content">
                <div className="row h-100">
                    <div id="logoImageDiv" className="col-12 col-md-6 pr-md-5 hk-logo"
                    // style="background-image: "${homePageLogoPictureUrl}"`
                    ></div>

                    <div className="col-12 d-flex py-3 justify-content-center col-md-6 py-md-0 pl-md-5 justify-content-md-start">
                        <div id="form" className="d-flex flex-column text-white">
                            {/* <h3>Tilbakestill passord</h3> */}
                            <h3>{this.state.localizationStrings.RESET_PASSWORD}</h3>
                            {/* <h5>Vi undersøker problemer med at engangskoden ikke blir verifisert riktig. For bytte av passord, kontakt kundeservice på
                    <br />
                    <br />
                    kundeservice@innit.no
                    <br />
                    40 00 33 55
                    <br />
                    (eller ditt vipnummer)
                    </h5> */}
                            {form}
                        </div>

                    </div>
                </div>
            </div>




        );
    }

    // captchaCallback = (a, b, c) => {
    //     debugger;
    // }
    async getCustomerConfig(tenantId) {
        //debugger;
        let result = null;

        const allTenantConfigsResult = await fetch("customersConfigsData.js");
        const allTenantConfigs = await allTenantConfigsResult.json();

        const foundCustomerConfig = allTenantConfigs.filter(tenantConfig => {
            return tenantConfig.tenantId == tenantId;
        });
        if (foundCustomerConfig.length > 0) {
            result = foundCustomerConfig[0];



        }
        //debugger;
        return result;
    }

    onChangeTextField = (event) => {
        //debugger;
        this.setState({
            [event.currentTarget.name]: event.currentTarget.value
        })
    }


    onClickRequestVerificationCode = () => {
        //debugger;

        //validation of login name and mobile phone
        let formIsValid = true;
        //validate login name
        let loginNameValidationMessage = "";
        if (this.state.loginName.trim() === "") {
            loginNameValidationMessage = this.state.localizationStrings.EMAIL_IS_A_MANDATORY_FIELD;
            formIsValid = formIsValid && false;
        }
        let LOGIN_NAME_FORMAT = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!LOGIN_NAME_FORMAT.test(this.state.loginName)) {
            loginNameValidationMessage = this.state.localizationStrings.EMAIL_IS_NOT_IN_THE_CORRECT_FORMAT;
            formIsValid = formIsValid && false;
        }

        //validate mobile phone
        let mobilePhoneValidationMessage = "";
        if (this.state.countryCode.trim() === "" || this.state.mobilePhone.trim() === "") {
            mobilePhoneValidationMessage = this.state.localizationStrings.MOBILE_PHONE_IS_A_MANDATORY_FIELD;
            formIsValid = formIsValid && false;
        }
        let INTERNATIONAL_PHONE_NUMBER_FORMAT = /^\+[1-9]{1}[0-9]{3,14}$/; //+123456789
        if (!INTERNATIONAL_PHONE_NUMBER_FORMAT.test(this.state.countryCode + this.state.mobilePhone)) {
            mobilePhoneValidationMessage = this.state.localizationStrings.MOBILE_PHONE_IS_NOT_IN_THE_CORRECT_FORMAT;
            formIsValid = formIsValid && false;
        }

        this.setState({
            loginNameValidationMessage: loginNameValidationMessage,
            mobilePhoneValidationMessage: mobilePhoneValidationMessage
        })

        if (!formIsValid) {
            return;
        }


        this.setState({
            operationInProgress: true,
            loginNameValidationMessage: "",
            mobilePhoneValidationMessage: ""
        })
        //create the post request here to azure function that creates a verification code
        var request = new XMLHttpRequest();

        request.open('POST', this.state.endpointRootUrl + '/api/RequestVerificationCode', true);

        //form body
        var body = {
            TenantId: this.state.tenantId,
            LoginName: this.state.loginName,
            CountryCode: this.state.countryCode,
            MobilePhone: this.state.mobilePhone,
            ReCaptchaResponse: this.state.reCaptchaResponse
        }

        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload = (d) => {
            //debugger;
            //check the result of the password change and redirect accordingly
            switch (d.target.status) {
                //this is where the ResetPassword azure function returns after creating a password change job
                case 200:
                    //debugger;
                    //OK RESULT
                    //the verification code was sent to mobile phone

                    //setup timer for enterign verification code
                    let countDownDate = new Date().getTime() + this.state.verificationCodeValidMinutes * 60000;

                    // Update the count down every 1 second
                    let timerFunction = setInterval(() => {
                        //debugger;
                        // Get today's date and time
                        let now = new Date().getTime();

                        // Find the distance between now and the count down date
                        let distance = countDownDate - now;

                        // Time calculations for days, hours, minutes and seconds
                        let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                        // Output the result in an element with id="verificationCodeTimer"
                        this.setState({
                            timerMinutes: minutes,
                            timerSeconds: seconds
                        })
                        //document.getElementById("verificationCodeTimer").innerHTML = minutes + "m " + seconds + "s ";

                        // If the count down is over, write some text
                        if (distance < 0) {
                            //debugger;
                            clearInterval(this.state.timerFunction);
                            this.setState({
                                wfStep: "initial",
                                timerMessage: "Engangskoden er ikke gyldig lenger. Oppdater siden og prøv på nytt.",
                                timerMinutes: "0",
                                timerSeconds: "0",
                                loginName: "",
                                loginNameDisabled: false,
                                countryCode: "",
                                countryCodeDisabled: false,
                                mobilePhone: "",
                                mobilePhoneDisabled: false,
                                verificationCode: "",
                                verificationCodeDisabled: false,
                                password: "",
                                passwordRepeat: "",
                                passwordValidationMessage: ""
                            })
                            //document.getElementById("verificationCodeTimer").innerHTML = "CODE IS NOT VALID ANYMORE";
                            //document.getElementById("btnSubmitVerificationCode").setAttribute("disabled", "");
                        }
                    }, 1000);


                    this.setState({
                        wfStep: "checkVerificationCode",
                        loginNameDisabled: true,
                        countryCodeDisabled: true,
                        mobilePhoneDisabled: true,
                        operationInProgress: false,
                        timerFunction: timerFunction
                    });
                    break;
                // case 400:
                //     //
                //     this.setState({
                //         reCaptchaValidationMessage: d.target.response,
                //         operationInProgress: false
                //     });
                //     console.log(d.target);
                //     break;
                case 500:

                default:
                    //debugger;
                    this.setState({
                        wfStep: "requestVerificationCodeError",
                        loginNameDisabled: false,
                        countryCodeDisabled: false,
                        mobilePhoneDisabled: false,
                        operationInProgress: false
                    });
                    console.log(d.target);
                    break;
            }

        };
        request.send(JSON.stringify(body));
    }
    onClickConfirmVerificationCode = () => {
        //debugger;
        this.setState({
            operationInProgress: true
        })
        //create a post request which will check if the entered verification code is correct
        var request = new XMLHttpRequest();

        request.open('POST', this.state.endpointRootUrl + '/api/ConfirmVerificationCode', true);

        //form body
        var body = {
            TenantId: this.state.tenantId,
            LoginName: this.state.loginName,
            CountryCode: this.state.countryCode,
            MobilePhone: this.state.mobilePhone,
            VerificationCode: this.state.verificationCode
        }

        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload = (d) => {
            //debugger;
            //check the result of the password change and redirect accordingly
            switch (d.target.status) {
                //this is where the ResetPassword azure function returns after creating a password change job
                case 200:
                    //debugger;
                    //OK RESULT
                    //clear the timer
                    clearInterval(this.state.timerFunction);
                    //the verification code was sent to mobile phone
                    this.setState({
                        wfStep: "passwordEntry",
                        loginNameDisabled: true,
                        countryCodeDisabled: true,
                        mobilePhoneDisabled: true,
                        verificationCodeDisabled: true,
                        operationInProgress: false
                    });
                    break;
                case 500:
                default:
                    //debugger;
                    this.setState({
                        wfStep: "checkVerificationCodeError",
                        loginNameDisabled: true,
                        countryCodeDisabled: true,
                        mobilePhoneDisabled: true,
                        verificationCodeDisabled: false,
                        operationInProgress: false
                    });
                    console.log(d.target);
                    break;
            }

        };
        request.send(JSON.stringify(body));
    }
    onClickResetPassword = () => {
        //debugger;

        //validation of password
        if (this.state.password.trim() === "" || this.state.passwordRepeat.trim() === "") {
            this.setState({
                passwordValidationMessage: this.state.localizationStrings.PASSWORD_IS_A_MANDATORY_FIELD
            })
            return;
        }
        if (this.state.password.trim() !== this.state.passwordRepeat.trim()) {
            this.setState({
                passwordValidationMessage: this.state.localizationStrings.PASSWORDS_DO_NOT_MATCH
            })
            return;
        }

        debugger;
        //get password regex pattern from costomer config
        const regexPatternKey = this.state.customerConfig.passwordPolicy.regexPatternKey;//CUSTOMER_SPECIFIC_PASSWORD_COMPLEXITY_STRING__MAKE_GRAPHICS
        const regexPattern = PasswordRegexPatterns[regexPatternKey];
        var password = this.state.password.trim();
        var patt = new RegExp(regexPattern); //            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/
        var res = patt.test(password);

        if (!res) {
            return;
        }

        // if (this.state.password.trim().length < 6
        //     //||
        //     //!/[A-Z]/.test(this.state.password.trim()) || //does not have uppercase
        //     //!/[a-z]/.test(this.state.password.trim()) || //does not have lowecase
        //     //!/\d/.test(this.state.password.trim())//does not have digits

        // ) {
        //     this.setState({
        //         //passwordValidationMessage: "Password must be at least 6 characters and contain uppercase character(s), lowercase character(s), digit(s)"
        //         passwordValidationMessage: "Passordet må være minst 6 tegn"
        //     })
        //     return;
        // }

        //   alert("bad password");
        // var hasUpperCase = /[A-Z]/.test(password);
        // var hasLowerCase = /[a-z]/.test(password);
        // var hasNumbers = /\d/.test(password);
        // var hasNonalphas = /\W/.test(password);
        // if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3)
        //   alert("bad password");


        this.setState({
            operationInProgress: true
        })
        //create a post request which will reset the password
        var request = new XMLHttpRequest();

        request.open('POST', this.state.endpointRootUrl + '/api/ResetPassword', true);

        //form body
        var body = {
            TenantId: this.state.tenantId,
            LoginName: this.state.loginName,
            CountryCode: this.state.countryCode,
            MobilePhone: this.state.mobilePhone,
            VerificationCode: this.state.verificationCode,
            Password: this.state.password
        }

        request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        request.onload = (d) => {
            //debugger;
            //check the result of the password change and redirect accordingly
            switch (d.target.status) {
                //this is where the ResetPassword azure function returns after creating a password change job
                case 200:
                    //debugger;
                    //OK RESULT
                    //the verification code was sent to mobile phone
                    this.setState({
                        wfStep: "passwordChangedSuccess",
                        operationInProgress: false
                    });
                    break;
                case 500:
                default:
                    //debugger;
                    this.setState({
                        wfStep: "checkVerificationCodeError",
                        loginNameDisabled: false,
                        countryCodeDisabled: false,
                        verificationCodeDisabled: false,
                        operationInProgress: false
                    });
                    console.log(d.target);
                    break;
            }
        };
        request.send(JSON.stringify(body));
    }
}

export default Home;



