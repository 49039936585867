import React from 'react';



class VerificationCodeField extends React.Component {

    render() {
        return (
            <div className="d-block mb-2 mb-sm-0 d-sm-table-row">
                <label className="d-inline-block d-sm-table-cell no-white-space">
                    {this.props.localizedLabelName}:
                </label>
                <input
                    className="d-block d-sm-table-cell form-control"
                    type="text"
                    autoComplete="__disabled"
                    value={this.props.verificationCode}
                    onChange={this.props.onChangeTextField}
                    name="verificationCode"
                    disabled={this.props.disabled}
                />
                <div className="invalid-feedback">
                    {this.props.verificationCodeValidationMessage}
                </div>
            </div>
        );
    }
}

export default VerificationCodeField;